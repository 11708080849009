<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')">

			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" notes="This is seen by attendees when submitting events." />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Default" v-model="model.default" :validation="$v.model.default" :options="$constants.schedule.type.defaultName" :descriptions="$constants.schedule.type.defaultDescription" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Usage" v-model="model.public" :validation="$v.model.public" :options="$constants.schedule.type.publicName" :descriptions="$constants.schedule.type.publicDescription" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Games" v-model="model.games.enabled" :validation="$v.model.games.enabled" :options="$constants.schedule.type.gamesName" :descriptions="$constants.schedule.type.gamesDescription" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" title="Access" subtitle="Badge holders that can see this type when submitted events" icon="moderators" v-if="!model.public">

			<app-input-suggest :multiple="true" placeholder="Search badge" v-model="model.access" api="convention/badge" :validation="$v.model.access" notes="You do not need to add admins or moderators as they already will have access." />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			model: {
				id: false,
				name: '',
				default: 0,
				public: 1,
				games: {
					enabled: 1
				},
				access: []
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			games: {}
		}
	}

}

</script>

<style scoped>

</style>